<template>
  <div class="row mob-cart">
    <div class="col-md-5">
      <p class="del_address">Тип доставки:</p>
      <label class="control control--radio" v-for="(type, key) in deliveryTypes" :key="key">
        {{type.name}}
        <input type="radio" name="radio" :checked="type.key == deliveryType" :value="deliveryType" @change="selectDeliveryType(type.key)" />
        <div class="control__indicator"></div>
      </label>
    </div>
    <div class="col-md-7">
      <div class="d-flex align-items-center" v-if="isSelectedDeliveryToTheOffice">
        <span class="icon-error float-left mr-2"></span>
        <p class="notify-note">Доставка в офис осуществляется каждую пятницу. Заказы, сделанные в среду после 13:00, будут доставлены в пятницу следующей недели.</p>
      </div>
      <div class="d-flex align-items-center" v-if="isSelectedDeliveryToMoscow || isSelectedDeliveryToOtherRegion">
        <span class="icon-error float-left mr-2"></span>
        <p class="notify-note">Сроки доставки не менее десяти дней</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CartDeliveryType",
  computed: {
    ...mapGetters('cart/subdivision', [
      'deliveryType',
      'deliveryTypes',
      'isSelectedDeliveryToTheOffice',
      'isSelectedDeliveryToMoscow',
      'isSelectedDeliveryToOtherRegion',
    ])
  },
  methods: {
    ...mapActions('cart/subdivision', ['selectDeliveryType'])
  }
}
</script>

<style scoped>
h3 {
  padding-top: 30px;
}

.table thead th {
  border: none !important;
}

th {
  font-weight: 700;
  font-size: 12px;
}

.table td, .table th {
  border: none;
  border-bottom: 1px solid rgba(179, 179, 179, 0.35);
}

.table td {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
}

td {
  font-weight: 600;
  font-size: 15px;
  vertical-align: middle;
  font-size: 16px;
}

.del_address {
  font-weight: bold;
  font-size: 18px;
}

.table thead th {
  font-size: 14px;
  border-top: 0;
  font-weight: bold;
}

.notify-note {
  font-size: 12px;
  line-height: normal;
  margin-bottom: 0;
}

.icon-error {
  display: inline-block;
  background-repeat: no-repeat;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
}

.icon-error {
  background-image: url("../../assets/images/alarm.svg");
}

@media (max-width: 767px) {
  .mob-cart {
    padding: 20px 0;
  }

  .mob-cart .control {
    font-size: 13px;
  }

  .del_address {
    font-size: 15px;
  }

  .icon-error {
    margin-bottom: 20px;
  }
}





</style>
