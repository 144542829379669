<template>
  <div>
    <div
      v-if="(items && items.length > 0) || (itemsLongTermProduction && itemsLongTermProduction.length)"
      class="container full-section mb-5"
    >
      <h1 class="mt-3">Корзина</h1>
      <cart-content :long-term-lines="itemsLongTermProduction" :lines="items"/>
    </div>
    <empty-cart v-if="(!items || items.length === 0) && (!itemsLongTermProduction || itemsLongTermProduction.length === 0)" />
  </div>
</template>

<script>
import CartContent from "./CartContent";
import EmptyCart from "./EmptyCart"

import {mapGetters} from "vuex";

export default {
  name: "Cart",
  components: {
    CartContent,
    EmptyCart
  },
  computed: {
    ...mapGetters('cart', ['items', 'itemsLongTermProduction'])
  }
}
</script>

<style scoped>
</style>
