<template>
    <div>
        <p class="result">Итого: {{ getTotal| fractionSizeSpace }}
            {{ makeDeclension(getTotal, "балл", "балла", "баллов") }}</p>
        <p class="balance">Баланс: {{ dealerBalance | fractionSizeSpace }}
            {{ makeDeclension(dealerBalance, "балл", "балла", "баллов") }}</p>
        <p v-if="isEnoughBalance" class="remainder">Остаток: {{ dealerBalance - getTotal | fractionSizeSpace }}
            {{ makeDeclension(dealerBalance - getTotal, "балл", "балла", "баллов") }}</p>
        <p v-if="!isEnoughBalance" class="lacks">Не хватает: {{ Math.abs(dealerBalance - getTotal)| fractionSizeSpace }}
            {{ makeDeclension(dealerBalance - getTotal, "балл", "балла", "баллов") }}</p>
        <div class="form-group mt-4">
            <button
                :disabled="disabledSubmit"
                class="btn btn-default fl-sm"
                @click="createOrder()"
            >
                Оформить заказ
            </button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import EntityMixin from "@/mixins/EntityMixin";

export default {
    name: "CartTotals",
    mixins: [EntityMixin],
    computed: {
        ...mapGetters('common', ['makeDeclension']),
        ...mapGetters('cart/dealer', [
            'isEnoughBalance',
            'dealerBalance',
            'isProductOrderCreating',
            'deliveryPhone',
            'deliveryEmail',
            'dealerStocksSale',
            'splitOrder',
        ]),
        ...mapGetters('cart', ['items', 'itemsLongTermProduction', 'getTotal']),
        ...mapGetters('builtInParameter', ['closeOrdersDealerByDate']),
        ...mapGetters( 'account', [ 'accountInfo' ] ),
        closePurchaseByDate() {
            if (this.dealerStocksSale.value === '1' && this.isCurrentUserDealer) {
                return false
            }
            return this.closeOrdersDealerByDate !== ''
        },
        checkEmailValid() {
            const regexp= /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
            return !!this.deliveryEmail.match(regexp)
        },
        checkPhoneValid() {
            return this.deliveryPhone.length >=11
        },
        disabledSubmit() {
          if (+this.accountInfo.dealerUniqueId === 789654123) {
            return !this.isEnoughBalance ||
              this.isProductOrderCreating ||
              !this.checkEmailValid ||
              !this.checkPhoneValid ||
              (!!this.items.length && !!this.itemsLongTermProduction.length && this.splitOrder === null)
          }
          return !this.isEnoughBalance ||
              this.isProductOrderCreating ||
              !this.checkEmailValid ||
              !this.checkPhoneValid ||
              this.closePurchaseByDate ||
              (!!this.items.length && !!this.itemsLongTermProduction.length && this.splitOrder === null)

        }
    },
    methods: {
        ...mapActions('cart/dealer', ['createOrder']),
    }
}
</script>

<style lang="sass" scoped>
.lacks
    margin: 17px 0
    color: #e5173f
    line-height: 1.71
    font-family: Futuris
    font-size: 14px
</style>
