<template>
  <table class="table prod-table">
    <thead>
    <tr>
      <th></th>
      <th>Товар</th>
      <th v-if="isCurrentUserDealer">Цена</th>
      <th style="width: 250px">Kоличество</th>
      <th>{{ isCurrentUserDealer ? 'Сумма' : 'Баланс' }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="lines.length && longTermLines.length">
      <td colspan="6">
        <h2 class="table-subtitle">Товары со стандартным сроком производства</h2>
      </td>
    </tr>
    <component
      v-for="(line, key) in lines"
      :is="isCurrentUserStructuralSubdivisionUser ? 'cart-subdivision-line' : 'cart-dealer-line'"
      :line="line"
      :key="key + line.quantity"
    />
    <tr v-if="lines.length && longTermLines.length">
      <td colspan="6">
        <h2 class="table-subtitle">Товары длительного производства</h2>
      </td>
    </tr>
    <component
      v-for="(line, key) in longTermLines"
      :is="isCurrentUserStructuralSubdivisionUser ? 'cart-subdivision-line' : 'cart-dealer-line'"
      :line="line"
      :key="key"
    />
    </tbody>
  </table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CartLinesDesktop",
  components: {
    CartSubdivisionLine: () => import('@/components/subdivision_user/CartLineDesktop.vue'),
    CartDealerLine: () => import('@/components/dealer/CartLineDesktop.vue'),
  },
  props: {
    longTermLines: {
      type: Array,
      default: () => []
    },
    lines: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('account', ['isCurrentUserStructuralSubdivisionUser', 'isCurrentUserDealer']),
  },
}
</script>

<style scoped>
.table thead th {
  border: none !important;
}

.table-subtitle {
  font-size: 1.1rem;
  font-weight: bold;
}

th {
  font-weight: 700;
  font-size: 12px;
}

.table td, .table th {
  border: none;
  border-bottom: 1px solid rgba(179, 179, 179, 0.35);
}

.table thead th {
  font-size: 14px;
  border-top: 0;
  font-weight: bold;
}

@media (max-width: 767px) {
  .table {
    display: none;
  }
}
</style>
