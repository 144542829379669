<template>
  <div>
    <lines-desktop :long-term-lines="longTermLines" :lines="lines"/>
    <lines-mobile :long-term-lines="longTermLines" :lines="lines"/>
    <template v-if="isCurrentUserStructuralSubdivisionUser">
      <delivery/>
      <cart-info/>
    </template>
    <template v-if="isCurrentUserDealer">
      <div class="row mob-cart">
        <delivery-addresses class="col-6 col-md-8"/>
        <totals class="col-6 col-md-4 text-right"/>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CartLinesDesktop from "./CartLinesDesktop.vue";
import CartLinesMobile from "./CartLinesMobile.vue";
import CartDelivery from "@/components/subdivision_user/CartDelivery.vue";
import CartInfo from "@/components/subdivision_user/CartInfo.vue";
import CartDeliveryAddresses from "@/components/dealer/CartDeliveryAddresses";
import CartTotals from "@/components/dealer/CartTotals";

export default {
  name: "DesktopCart",
  components: {
    'lines-desktop': CartLinesDesktop,
    'lines-mobile': CartLinesMobile,
    'delivery-addresses': CartDeliveryAddresses,
    'totals': CartTotals,
    'delivery': CartDelivery,
    'cart-info': CartInfo,
  },
  props: {
    longTermLines: {
      type: Array,
      default: () => []
    },
    lines: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('account', ['isCurrentUserStructuralSubdivisionUser', 'isCurrentUserDealer']),
  },
}
</script>
